import {NON_ATTRIBUTE_FILTERS, REGEX_MAPPING} from '../constants'
import {mapProductAttributeNames} from '../utils/product_attributes_mapping'

export const ATTRIBUTE_IDENTIFIER = 'CharacteristicsValue/'
export const ATTRIBUTE_IDENTIFIER_NEW = 'characteristicsValues/'
export const NON_ATTRIBUTE_FIELDS = ['sku', NON_ATTRIBUTE_FILTERS.PRIMARYL2CATEGORY.toLowerCase(), NON_ATTRIBUTE_FILTERS.BRAND.toLowerCase(), NON_ATTRIBUTE_FILTERS.DESIGN.toLowerCase(), NON_ATTRIBUTE_FILTERS.MATERIALIDENTIFIER.toLowerCase(), 'pricingflag', 'customfilterbyinch', 'customfilterbuyableproducts', 'customfiltershowinchvalues', NON_ATTRIBUTE_FILTERS.POLYMER.toLowerCase(), NON_ATTRIBUTE_FILTERS.SHORE_HARDNESS.toLowerCase()]
export const DEFAULT_VALUES = {
  SKIP: 0,
  TAKE: 25,
  FILTER_MULTISELECT_VALUE: [],
  FILTER_RANGE_VALUE: {from: null, tolerance: 0},
  FILTER_TEXT_VALUE: {label: null, value: ''},
  FILTER_DROPDOWN_VALUE: {},
  FILTER_DATE_VALUE: {start: null, end: null},
  FILTER_TRANSLATE_PREFIX: 'filters'
}
export const VALUE_TYPES = {
  INTEGER: 'Integer',
  FLOAT: 'Float',
  STRING: 'String',
  DATE: 'Date',
  DROPDOWN: 'Dropdown',
  TEXT: 'Text',
  MULTI_DOUBLE: 'Multiple Double',
  MULTI_STRING: 'Multiple String',
  MULTI_INTEGER: 'Multiple Integer'
}
export const CATEGORY_FILTER = NON_ATTRIBUTE_FILTERS.PRIMARYL2CATEGORY

export const INCH_FILTER = {shortName: 'customFilterByInch', isActive: true, customQuery: 'inchSearch eq true'}
export const SHOW_INCH_VALUES_FILTER = {shortName: 'customFilterShowInchValues', isActive: true, customQuery: 'measurement eq \'inch\''}

/**
 * Returns a cleaned up and sorted category filter list from the given category filters from CMS / or static object array.
 * The returned object array consists only the necessary properties for filtering.
 * @param filters
 * @returns {*[]}
 */
export function cleanupFilters(filters) {
  filters.sort((x, y) => {
    if (y.IsCoreFilter && !x.IsCoreFilter) {
      return -1
    }
    else if (!y.IsCoreFilter && x.IsCoreFilter) {
      return 1
    }
    else {
      return (typeof(x.FilterSortOrder) === 'undefined' ? 999999999 : x.FilterSortOrder) - (typeof(y.FilterSortOrder) === 'undefined' ? 999999999 : y.FilterSortOrder)
    }
  })
  const cleanedFilters = []
  filters.forEach(filter => {
    if (typeof(filter.Attribute.IsFilterable) !== 'undefined' && !filter.Attribute.IsFilterable) {
      return
    }
    let newFilter = {
      shortName: filter.Attribute.TechnicalName,
      indexPath: typeof(filter.Attribute.IndexPath) === 'undefined' ? null : filter.Attribute.IndexPath,
      unitOfMeasure: filter.Attribute.UnitOfMeasure,
      isCoreFilter: filter.IsCoreFilter,
      filterSortOrder: filter.FilterSortOrder,
      isCollectionFilter: !!filter.IsCollectionFilter,
      collectionName: typeof(filter.CollectionName) === 'undefined' ? null : filter.CollectionName,
      collectionKey: typeof(filter.CollectionKey) === 'undefined' ? null : filter.CollectionKey,
      type: filter.Attribute.ValueType,
      values: setFilterValues(filter.Attribute.ValueType),
      translateLabel: !!filter.Attribute.TranslateLabel,
      customTranslatePrefix: typeof(filter.Attribute.CustomTranslatePrefix) === 'undefined' ? null : filter.Attribute.CustomTranslatePrefix,
      isFilteredBy: false,
      isActive: false
    }
    cleanedFilters.push(newFilter)
  })

  return cleanedFilters
}

export function isNoAttributeFilter(filterName, newSearch = false) {
  if (newSearch) {
    const nonAttributeFieldsNew = NON_ATTRIBUTE_FIELDS.map(f => mapProductAttributeNames(f))
    return nonAttributeFieldsNew.indexOf(filterName ? filterName.toLowerCase() : null) !== -1
  }
  return NON_ATTRIBUTE_FIELDS.indexOf(filterName ? filterName.toLowerCase() : null) !== -1
}

export function setFilterValues(type) {
  switch (type) {
  case VALUE_TYPES.STRING: return DEFAULT_VALUES.FILTER_MULTISELECT_VALUE
  case VALUE_TYPES.MULTI_STRING: return DEFAULT_VALUES.FILTER_MULTISELECT_VALUE
  case VALUE_TYPES.MULTI_INTEGER: return DEFAULT_VALUES.FILTER_MULTISELECT_VALUE
  case VALUE_TYPES.MULTI_DOUBLE: return DEFAULT_VALUES.FILTER_MULTISELECT_VALUE
  case VALUE_TYPES.FLOAT: return Object.assign({}, DEFAULT_VALUES.FILTER_RANGE_VALUE)
  case VALUE_TYPES.INTEGER: return Object.assign({}, DEFAULT_VALUES.FILTER_RANGE_VALUE)
  case VALUE_TYPES.DROPDOWN: return DEFAULT_VALUES.FILTER_DROPDOWN_VALUE
  case VALUE_TYPES.DATE: return DEFAULT_VALUES.FILTER_DATE_VALUE
  case VALUE_TYPES.TEXT: return DEFAULT_VALUES.FILTER_TEXT_VALUE
  }
}

export function cleanupFacets(facets) {
  if (Array.isArray(facets)) {
    return facets.map(f => {
      if (REGEX_MAPPING.PolymerPrimary.test(f)) {
        return NON_ATTRIBUTE_FILTERS.POLYMER
      }
      if (REGEX_MAPPING.ShoreHardnessPrimary.test(f)) {
        return NON_ATTRIBUTE_FILTERS.SHORE_HARDNESS
      }
      if (REGEX_MAPPING.MaterialWithIndex.test(f)) {
        return NON_ATTRIBUTE_FILTERS.MATERIALIDENTIFIER
      }
      if (REGEX_MAPPING.Characteristics.test(f)) {
        const find = f.match(REGEX_MAPPING.Characteristics)
        if (find && find.length === 3) {
          return 'CharacteristicsValue/' + find[2]
        }
      }
      return f
    })
  }
  return []
}

export function sortFilters(filters) {
  return filters.sort((x, y) => {
    if (y.isCoreFilter && !x.isCoreFilter) {
      return -1
    }
    else if (!y.isCoreFilter && x.isCoreFilter) {
      return 1
    }
    else {
      return (typeof(x.filterSortOrder) === 'undefined' ? 999999999 : x.filterSortOrder) - (typeof(y.filterSortOrder) === 'undefined' ? 999999999 : y.filterSortOrder)
    }
  })
}
